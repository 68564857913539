
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import path from "path";
import { IFileInputEvent } from "@/interfaces/IFileInputEvent";
import { IUploadFile } from "@/interfaces/IUploadFile";

/*
 **** USAGE *******************************************************************************************
 *
 *    • The parent component must set up a binding for the following 3 properties using v-model
 *
 *          fileIsSelected: boolean
 *          fileIsReady: boolean
 *          file: object (implements IUploadFile)
 *
 *    • The parent component must set a reference to this component so that it can emit the file object and fileIsReady value
 *      by calling this component's emitFile method, like so:
 *
 *          this.$refs.fileUpload.emitFile();
 *          while (!this.fileIsReady);
 *
 *          const notesUpload = this.file
 *
 *          // call to upload service
 *
 *    • The maximum permitted file size for upload can be passed as a prop
 *    • A comma delimited string of permitted file types for upload can be passed as a prop
 *
 *    Example:
 *
 *    <FileUpload
 *      ref="fileUpload"
 *      maxSizeInMb="20"
 *      :permittedFileTypes="permittedFileTypes"
 *      v-model:fileIsSelected="fileIsSelected"
 *      v-model:fileIsReady="fileIsReady"
 *      v-model:file="file"
 *    />
 *
 *****************************************************************************************************/

@Options({
  props: {
    /*
     * The maximum permitted file size for upload (in megabytes)
     * The default value will cancel the validation
     */
    maxSizeInMb: {
      type: Number as PropType<number>,
      required: false,
      default: 0,
    },
    /*
     * A comma delimited string of permitted file types for upload
     * The default value will cancel the validation
     */
    permittedFileTypes: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
  },
  data: () => ({
    /*
     * An array of validation errors displayed to the user
     */
    validationErrors: new Array<string>(),
    /*
     * The file object to return to the parent component
     */
    file: {
      name: "",
      size: 0,
      type: "",
      extension: "",
      binaryData: "",
    },
    /*
     * Used to display or hide the file upload input
     * and also emitted to the parent component
     */
    fileIsSelected: false,
    /*
     * Used to display or hide the change file button
     */
    showChangeButton: true,
  }),
  emits: ["update:fileIsSelected", "update:file", "update:fileIsReady"],
  methods: {
    /*
     * Populates the file object to return to the parent component if the selected file passes validation
     */
    handleFileChange(e: IFileInputEvent): void {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];

        if (this.isFileValid(file)) {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              const uploadFile: Partial<IUploadFile> = {
                name: file.name,
                size: file.size,
                type: file.type,
                extension: this.getFileExtension(file.name),
                lastModified: file.lastModified,
                binaryData: reader.result,
              };
              this.file = uploadFile;
            },
            false
          );
          reader.readAsDataURL(file);

          this.emitFileIsSelected(true);
        }
      }
    },
    /*
     * Validates the selected file
     */
    isFileValid(file: File): boolean {
      this.validationErrors = [];
      this.isValidFileSize(this.bytesToMegabytes(file.size));
      this.isValidFileType(this.getFileExtension(file.name));
      return this.validationErrors.length === 0;
    },
    /*
     * Validates file size
     */
    isValidFileSize(fileSize: number): void {
      if (this.maxSizeInMb === 0) {
        return;
      }
      if (fileSize > this.maxSizeInMb) {
        this.validationErrors.push(
          `${this.$t("file-upload.file-size-error")} ${this.maxSizeInMb} MB`
        );
      }
    },
    /*
     * Validates file type
     */
    isValidFileType(fileExtension: string): void {
      if (this.permittedFileTypes.length === 0) {
        return;
      }
      const permittedTypes = this.permittedFileTypes
        .split(",")
        .map((ext: string) => ext.trim());

      if (!permittedTypes.includes(fileExtension)) {
        this.validationErrors.push(
          `${this.$t("file-upload.file-type-error")} ${this.permittedFileTypes}`
        );
      }
    },
    /*
     * Resets the file object and displays the file upload input
     */
    resetFileInput(): void {
      this.$nextTick(() => {
        this.file = {
          name: "",
          size: 0,
          type: "",
          data: "",
          extension: "",
          binaryData: "",
        };
      });
      this.emitFileIsSelected(false);
    },
    /*
     * Emits the fileIsSelected value to the parent component
     */
    emitFileIsSelected(value: boolean): void {
      this.fileIsSelected = value;
      this.$emit("update:fileIsSelected", value);
    },
    /*
     * Emits the file object and the fileIsReady value to the parent component
     * Called from the parent component via reference, eg:
     *    this.$refs.fileUpload.emitFile();
     */
    emitFile(): void {
      this.showChangeButton = false;

      // Remove base64 encoding header, eg: data:application/pdf;base64,
      this.file.binaryData = this.file.binaryData.split(",")[1];

      this.$emit("update:file", this.file);
      this.$emit("update:fileIsReady", true);
    },
    /*
     * Opens the hidden file upload input
     */
    openFileInput(): void {
      this.validationErrors.length = 0;
      this.$refs.fileUpload.click();
    },
    /*
     * Converts bytes to megabytes
     */
    bytesToMegabytes(bytes: number): number {
      return Number((bytes / 1048576).toFixed(2));
    },
    /*
     * Gets the file extension from the file name
     */
    getFileExtension(fileName: string): string {
      return path.extname(fileName).slice(1);
    },
  },
})
export default class FileUpload extends Vue {}
