import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85a5c054"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-upload-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "file-upload" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "file-name" }
const _hoisted_7 = {
  key: 0,
  class: "button-row margin-top-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.fileIsSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              style: {"display":"block","width":"120px","height":"30px"},
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openFileInput && _ctx.openFileInput(...args)))
            }, _toDisplayString(_ctx.$t("file-upload.choose-file")), 1),
            _createTextVNode("  " + _toDisplayString(_ctx.$t("file-upload.no-file-chosen")) + " ", 1),
            _createElementVNode("input", {
              ref: "fileUpload",
              type: "file",
              name: "fileUpload",
              id: "fileUpload",
              style: {"display":"none"},
              "aria-label": "File Upload",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleFileChange($event)))
            }, null, 544)
          ]),
          (_ctx.validationErrors.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validationErrors, (error, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "file-upload-error margin-top-10",
                    key: index
                  }, [
                    _createElementVNode("span", null, _toDisplayString(error), 1)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.file.name), 1),
          (_ctx.showChangeButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("button", {
                  id: "changeBtn",
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resetFileInput && _ctx.resetFileInput(...args)))
                }, _toDisplayString(_ctx.$t("file-upload.change-file")), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}